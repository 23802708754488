@import './styles/dropdowns.css';
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --light-gradient-start: #14b8a6;
  --light-gradient-end: #0f766e;
  --main-header-height: 64px;
  --content-header-height: 60px;
  --sidebar-width: 260px;
  --details-panel-width: 420px;
}

/* AG GRID */

.ag-cell-inline-editing .ag-cell-wrapper,
.ag-cell-inline-editing .ag-cell-edit-wrapper,
.ag-cell-inline-editing .ag-cell-editor,
.ag-cell-inline-editing .ag-cell-editor .ag-wrapper,
.ag-cell-inline-editing .ag-cell-editor input {
  @apply bg-white text-neutral-800 w-fit h-fit leading-4 text-[10px] font-bold rounded;
}

.ag-theme-quartz .ag-cell-label-container {
  @apply text-slate-400 leading-4 text-[10px] font-bold uppercase;
}

.ag-theme-quartz .ag-floating-filter-input {
  @apply text-slate-400 leading-4 text-[10px] font-bold;
}

.ag-input-field-input {
  @apply pr-1;
}

.ag-icon .ag-icon-small-down {
  @apply text-amber-600;
}

.ag-cell-value .ag-cell .ag-cell-normal-height {
  @apply w-fit h-fit !important;
}

.ag-theme-quartz .ag-picker-field-wrapper,
.ag-theme-quartz-dark .ag-picker-field-wrapper,
.ag-theme-quartz-auto-dark .ag-picker-field-wrapper {
  @apply min-h-fit py-0.5 px-1.5;
}

.ag-floating-filter-full-body {
  @apply justify-center items-center;
}

.ag-header-cell-center .ag-header-cell-comp-wrapper .ag-cell-label-container .ag-header-cell-label {
  @apply flex justify-center items-center;
}

.ag-cell .ag-cell-not-inline-editing .ag-cell-normal-height .ag-cell-focus {
  @apply focus:outline-none;
}

.ag-cell-focus {
  @apply focus:outline-none;
}

.ag-cell-inline-editing .ag-cell-wrapper,
.ag-cell-inline-editing .ag-cell-edit-wrapper,
.ag-cell-inline-editing .ag-cell-editor,
.ag-cell-inline-editing .ag-cell-editor .ag-wrapper,
.ag-cell-inline-editing .ag-cell-editor input {
  @apply bg-transparent;
}

.ag-cell-wrapper {
  @apply h-full ml-1 text-slate-700;
}

.ag-player-detail .ag-root-wrapper {
  @apply rounded-none;
}

.ag-log-description .ag-cell-wrapper .ag-cell-value {
  @apply leading-3 break-normal;
}

.ag-theme-quartz {
  --ag-range-selection-border-color: transparent;
  --ag-selected-row-background-color: transparent;
  --ag-checkbox-checked-color: #6366f1;
}

.ag-row-even {
  @apply bg-slate-100;
}

.ag-cell-center {
  @apply flex justify-center items-center;
}

/* MATERIAL SELECT */

/* General */
.cdk-overlay-pane {
  @apply !w-fit;
}

/* Dropdown Select */
.mat-dropdown.mat-mdc-select {
  @apply !text-[12px] !leading-4  !rounded !font-semibold !text-slate-500;
}

/* Document status dropdown */
.mat-document-status.mat-mdc-select {
  @apply !text-center !text-[10px] !leading-4 !font-[600] !rounded;
}

.mat-document-status .mat-mdc-select-trigger {
  @apply gap-1;
}

.mat-document-status .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  @apply !text-inherit;
}

.mat-document-status .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow svg {
  @apply !w-5 !h-5;
}

.mat-document-status .mat-pseudo-checkbox {
  @apply !w-2 !h-2;
}

.mat-document-status .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  @apply !w-2 !h-1;
}

/* MATERIAL AUTOCOMPLETE */

/* Assignee dropdown */
.mat-document-assignee .mdc-text-field {
  @apply !px-0;
}

.mat-document-assignee .mat-mdc-text-field-wrapper {
  @apply !bg-transparent !w-fit;
}

.mat-document-assignee .mat-mdc-text-field-wrapper .mat-mdc-form-field-focus-overlay {
  @apply !bg-transparent;
}

.mat-document-assignee .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  @apply !min-h-3 !py-0 !w-fit;
}

.mat-document-assignee .mat-mdc-text-field-wrapper .mdc-line-ripple {
  @apply hidden;
}

.mat-document-assignee .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  @apply hidden;
}

.mat-document-assignee .mat-pseudo-checkbox {
  @apply !w-2 !h-2;
}

.mat-document-assignee .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  @apply !w-2 !h-1;
}
