.dropdown {
  @apply relative inline-block;
}

.dropdown .dropdown-content {
  @apply absolute left-0 top-full
  mt-2
  flex
  max-w-[200px]
  origin-top-left
  scale-0
  flex-col
  rounded-md
  bg-gray-100
  opacity-0
  shadow-lg
  ring-1 ring-black ring-opacity-5
  transition-all duration-100 ease-in-out
  max-h-[140px]
  overflow-y-auto
 
  focus:outline-none;
}

.dropdown .dropdown-icon {
  @apply rotate-0 px-2 transition-transform duration-100 ease-in-out;
}

.dropdown .dropdown-content .dropdown-list {
  @apply w-max py-1;
}

.dropdown .dropdown-content .dropdown-list .dropdown-list-item {
  @apply flex items-center justify-start
  whitespace-nowrap
  px-4 py-2
  text-sm leading-6
  hover:bg-gray-200 hover:text-gray-900;
}

.dropdown.open .dropdown-content {
  @apply scale-100 opacity-100;
}

.dropdown.open .dropdown-icon {
  @apply -rotate-180;
}

.dropdown.align-right .dropdown-content {
  @apply left-auto right-0 origin-top-right;
}

.dropdown .dropdown-content .dropdown-list.rounded-items .dropdown-list-item {
  @apply mx-1 rounded;
}
